// Generated by Framer (1b7c4bf)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Video } from "https://framerusercontent.com/modules/lRDHiNWNVWmE0lqtoVHP/tbiAmyP8q4mMaXLQcmj3/Video.js";
const VideoFonts = getFonts(Video);

const serializationHash = "framer-lGrrq"

const variantClassNames = {ig2meNnI3: "framer-v-18zntb0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "ig2meNnI3", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-18zntb0", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ig2meNnI3"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-ravzxs-container"} layoutDependency={layoutDependency} layoutId={"p6haEGsvM-container"}><Video backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={0} bottomLeftRadius={0} bottomRightRadius={0} controls={false} height={"100%"} id={"p6haEGsvM"} isMixedBorderRadius={false} layoutId={"p6haEGsvM"} loop muted objectFit={"cover"} playing posterEnabled srcFile={"https://framerusercontent.com/assets/dXBUE2aPtfceFO6UXjlPglURSM.mp4"} srcType={"Upload"} srcUrl={"https://framerusercontent.com/assets/MLWPbW1dUQawJLhhun3dBwpgJak.mp4"} startTime={0} style={{height: "100%", width: "100%"}} topLeftRadius={0} topRightRadius={0} volume={25} width={"100%"}/></motion.div></ComponentViewportProvider><ComponentViewportProvider ><motion.div className={"framer-1na4c9r-container"} layoutDependency={layoutDependency} layoutId={"sEgJ0_wMe-container"}><Video backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={0} bottomLeftRadius={0} bottomRightRadius={0} controls={false} height={"100%"} id={"sEgJ0_wMe"} isMixedBorderRadius={false} layoutId={"sEgJ0_wMe"} loop muted objectFit={"cover"} playing posterEnabled srcFile={"https://framerusercontent.com/assets/qEiyEVyA5MjxHE2Pwje17Op1lc.mp4"} srcType={"Upload"} srcUrl={"https://framerusercontent.com/assets/MLWPbW1dUQawJLhhun3dBwpgJak.mp4"} startTime={0} style={{height: "100%", width: "100%"}} topLeftRadius={0} topRightRadius={0} volume={25} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-lGrrq.framer-1bsmauf, .framer-lGrrq .framer-1bsmauf { display: block; }", ".framer-lGrrq.framer-18zntb0 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 112px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 412px; }", ".framer-lGrrq .framer-ravzxs-container, .framer-lGrrq .framer-1na4c9r-container { flex: none; height: 112px; position: relative; width: 200px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-lGrrq.framer-18zntb0 { gap: 0px; } .framer-lGrrq.framer-18zntb0 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-lGrrq.framer-18zntb0 > :first-child { margin-left: 0px; } .framer-lGrrq.framer-18zntb0 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 112
 * @framerIntrinsicWidth 412
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramermTNOSJPkh: React.ComponentType<Props> = withCSS(Component, css, "framer-lGrrq") as typeof Component;
export default FramermTNOSJPkh;

FramermTNOSJPkh.displayName = "Video Testimonial for mobile";

FramermTNOSJPkh.defaultProps = {height: 112, width: 412};

addFonts(FramermTNOSJPkh, [{explicitInter: true, fonts: []}, ...VideoFonts], {supportsExplicitInterCodegen: true})